.ruota-sdg {
    position: absolute;
    animation: ruota-sdg 0.5s forwards ease-out;

    &.on {
        .arc {
            opacity: 0.8;
            transform: rotate(var(--rotation)) !important;
        }
    }

    .arc {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 50%;
        transform-origin: 0% 100%;
        transition-property: transform;
        transition-duration: 0.3s;
        transform: rotate(var(--rotation));
        animation: arco-ruota-sdg 0.8s forwards ease-out;

        &:hover {
            cursor: pointer;
            filter: brightness(1.2);
        }

        img {
            position: absolute;
            top: 40%;
            left: 5.5%;
            transform: rotate(calc(var(--rotation) * -1));
            transition: color 0.3s;
            width: 60px;
        }
    }

    @for $i from 1 through 17 {
        &[data-chosen='#{$i}'] {
            .arc {
                &:nth-child(#{$i}) {
                    opacity: 1;
                    transform: rotate(var(--rotation)) !important;
                    filter: brightness(150%);
                }
            }
        }

        .arc {
            &:nth-child(#{$i}) {
                --rotation: #{($i - 1) * 21.2}deg;
                transition-delay: #{($i % 2) * 0.015}s;
            }
        }
    }
}

@keyframes ruota-sdg {
    0% {
        transform: scale(1.2);
        filter: blur(20px);
    }

    100% {
        transform: scale(1);
        filter: blur(0);
    }
}

@keyframes arco-ruota-sdg {
    0% {
        clip-path: polygon(10% 80%, 39% 10%, 0% 100%);
        transform-origin: 0% 80%;


    }

    100% {
        clip-path: polygon(0% 0%, 39% 0%, 0% 100%);
        transform-origin: 0% 100%;

    }
}