@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .btn-navigazione-modulo {
    @apply block bg-white dark:bg-darkgray-dd hover:opacity-80 text-accent text-center p-4 font-bold flex justify-center items-center gap-2 duration-200 basis-0;
  }
  .btn-navigazione-modulo-attivo {
    @apply bg-accent dark:bg-dark-accent text-white dark:text-white;
  }
}

* {
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  background: theme("colors.lightgray-d");
}
*::-webkit-scrollbar-thumb {
  background: theme("colors.accent");
  border-radius: 2px;
}
*::-webkit-scrollbar-thumb:hover {
  filter: brightness(1.15);
}
*::-moz-selection {
  background: theme("colors.accent");
  color: white;
}
*::selection {
  background: theme("colors.accent");
  color: white;
}

a {
  color: theme("colors.accent");
}

a,
button {
  transition-duration: 0.2s;
}
a:hover,
button:hover {
  filter: brightness(1.15);
}

.dark .dropdown-heading-value > span {
  color: white;
}

.dark a {
  color: theme("colors.dark-accent");
}

#follow-mouse {
  transform: translate(10px, 10px);
}

button {
  background-color: theme("colors.accent");
}

.dark button {
  background-color: theme("colors.dark-accent");
}

.InfoCircle{
  height: 60%;
  width: 90%;
  margin: 0 auto;
  border-color: #5D83C2;
}
.firstInfoConn{
  position: absolute;
  left: 96%;
  top: 68%;
  transform: rotate(32deg) rotateX(-50deg);
}
.secondInfoConn{
  bottom: 63%;
  position: absolute;
  left: 98%;
  transform: rotate(141deg) rotateX(-50deg);
}

.panoramicaTimeLineDots{
  border: 2px solid rgb(203, 202, 202) ;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: white;
  z-index: 2;
}
.panoramicaTimeLineDotsActive{
   background-color: rgb(37, 37, 200);
  -webkit-transition: background-color 0.2s; /* Safari */
          transition: background-color 0.2;
  -webkit-transition-delay: 0.2s; /* Safari */
  transition-delay: 0.2s;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  z-index: 2;
  box-shadow: 0px 0px 13px 4px rgb(25, 7, 182);
}

.panoramicaSvgActive{
  color:rgb(18, 129, 247)
}
.panoramicaSvg{
  color: #002E5A;
}
.panoramicaSvg:hover{
  filter: brightness(2);
}
.panoramicaTimeLine{
  height: 4px;
  background: #BBC7D5;
}
.panoramicaTimeLineActive{
  position: relative;
  background: #BBC7D5;
  height: 4px;
  width:  100%;
}

.panoramicaTimeLineActive:before{
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
  height: 4px;
  background: linear-gradient(90deg, rgb(53, 150, 255), blue);
  animation: border_anim 0.5s linear forwards;
}
@keyframes border_anim {
	0%{
		width: 0%;
	}
	100%{
		width: 100%;
	}
}
@keyframes panoramicaSpinner {
  to {transform: rotate(360deg);}
}
 
.Panoramicaspinner {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #2267c7;
  animation: panoramicaSpinner .6s linear infinite;
}
.panoramicaButtons{
  background-color: #F2F4F0;
}

.panoramicaButtons:hover{
  filter: none;
}
.panoramicaBg{
  background-color: #BBC7D5;
  margin-left: -25px !important;
  margin-right: -25px !important;
  margin-top: -25px !important;
}
.panoramicaVista{
  box-shadow: rgba(4, 168, 180, 0.35) 3px 10px 20px 1px,
  rgba(5, 23, 190, 0.69) -2px 8px 11px 1px;
}
.panoramicaTesto{
  color: #102543;
}
.btnActive{
  background-color:rgb(18, 129, 247)
}
.mostraFiltri{
  animation: mostrami 2s forwards;
}
@keyframes mostrami{
  0%{
      opacity: 0;
  }
  100%{
      opacity: 1;
  }
}
/*
 .form gestisce gli stili di tutti i form
*/
.form {
  margin-bottom: 10px;
  margin-top: 6px;
  width: 100%;
}
.form .multi-select {
  margin-bottom: 20px;
}
.form label {
  margin-bottom: 4px;
  display: block;
}
.form input {
  display: block;
  width: 100%;
  transition-duration: 0.2s;
  accent-color: theme("colors.accent");
}
.form input:disabled {
  filter: brightness(0.8);
  pointer-events: none;
}
.form .rmsc .dropdown-container {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 3px 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}
.form .css-b62m3t-container {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 3px 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}
.form .css-b62m3t-container .css-1s2u09g-control {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}
.form .css-b62m3t-container .css-1s2u09g-control .css-1hwfws3 {
  color: theme("colors.text");
}
.form .css-b62m3t-container .css-1pahdxg-control {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  box-shadow: none;
}
.form .css-b62m3t-container .css-1pahdxg-control .css-1hwfws3 {
  color: theme("colors.text");
}
.form .css-b62m3t-container .css-qc6sy-singleValue {
  color: #6a6a6a;
}
.form input[type=text],
.form input[type=password],
.form input[type=email],
.form input[type=tel],
.form input[type=time],
.form input[type=number],
.form input[type=search],
.form input[type=url],
.form input[type=submit],
.form select,
.form textarea {
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 12px 8px;
  width: 100%;
  display: block;
}
.form input[type=text]:focus,
.form input[type=password]:focus,
.form input[type=email]:focus,
.form input[type=tel]:focus,
.form input[type=time]:focus,
.form input[type=number]:focus,
.form input[type=search]:focus,
.form input[type=url]:focus,
.form input[type=submit]:focus,
.form select:focus,
.form textarea:focus {
  outline-color: theme("colors.accent");
}
.form input[type=submit] {
  display: block;
  margin-top: 0;
  border: none;
  color: theme("colors.lightgray");
  background-color: theme("colors.accent");
  cursor: pointer;
  text-transform: capitalize;
}
.form input[type=submit]:hover {
  filter: brightness(1.15);
}
.form input[type=checkbox] {
  width: auto;
  width: 15px;
  height: 15px;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  background-color: theme("colors.lightgray-d");
  padding: 10px;
  border-radius: 2px;
}
.checkbox label {
  margin: 0;
}

.dark .checkbox {
  background-color: theme("colors.darkgray-d");
}
.dark input {
  accent-color: theme("colors.dark-accent");
}
.dark input[type=submit] {
  background-color: theme("colors.dark-accent");
}
.dark option {
  background-color: theme("colors.darkgray-d");
  color: white;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.ipe-shad {
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.15);
}

.spinner {
  animation: spinner 2s infinite linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.toggle-theme {
  width: 40px;
  height: 20px;
  padding: 3px;
  border-radius: 10px;
  cursor: pointer;
}
.toggle-theme div {
  position: relative;
  width: 14px;
  height: 14px;
  left: 0px;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.toggle-theme .toggle-dark {
  left: 20px;
}

.ipe-logo path {
  fill: theme("colors.accent");
}

.dark .ipe-logo path {
  fill: theme("colors.dark-accent");
}

.modulotile-line {
  background: linear-gradient(0deg, theme("colors.accent") 50%, rgba(0, 0, 0, 0) 90%);
}

.text-loader {
  animation: textLoader 0.5s linear infinite;
  background-image: src("./loadshader.jpg");
  mix-blend-mode: multiply;
  opacity: 0.2;
}

@keyframes textLoader {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 300px;
  }
}
.tabella-generica {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 2px;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 900px) {
  .tabella-generica {
    max-width: calc(100vw - 20px);
  }
}
.tabella-generica thead {
  font-weight: 600;
  text-align: left;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.tabella-generica tbody {
  min-width: 100%;
}
.tabella-generica tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.1);
}
.tabella-generica th,
.tabella-generica td {
  padding: 15px 20px;
  text-align: left;
  font-weight: normal;
  min-width: 80px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.tabella-generica thead th {
  font-weight: bold;
}
.tabella-generica tr th:first-child {
  font-weight: bold;
}

.tabella-generica-minificata {
  width: 100%;
  text-align: left;
}
.tabella-generica-minificata thead {
  background-color: rgba(0, 0, 0, 0.1);
}
.tabella-generica-minificata td,
.tabella-generica-minificata th {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.tabella-generica-minificata tbody tr {
  width: 100%;
}
.tabella-generica-minificata tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.1);
}

.testo-attivita a {
  font-weight: bold;
}
.testo-attivita .azione {
  padding: 3px 6px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
}

.termini-condizioni p {
  margin: 10px 0;
  text-align: justify;
}

.aside-animato {
  animation: aside 0.1s ease-out forwards;
}

.dialogue-animato {
  animation: dialogue 0.2s ease-out forwards;
}

.sfondo-dialogue-animato {
  animation: sfondo-dialogue 0.2s ease-out forwards;
}

@keyframes aside {
  from {
    opacity: 0;
    width: 0px;
    min-width: 0px;
  }
  to {
    opacity: 1;
    width: 400px;
    min-width: 400px;
  }
}
@keyframes dialogue {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes sfondo-dialogue {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#toast {
  animation: toast 2s ease-in-out;
}

@keyframes toast {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  20% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}/*# sourceMappingURL=App.css.map */

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid#002E5A;
  color: #002E5A;
  cursor: pointer;
}

.dark .paginationBttns a {
  border: 1px solid theme("colors.dark-accent");
  color: theme("colors.dark-accent");
}

.richiesteRifiutaBtn{
  background-color: rgb(108, 2, 2) !important;
}

.paginationBttns{
  display: flex;
  justify-content: center;
}

.paginationBttns a:hover {
  color: white;
  background-color: #002E5A;
}

.dark .paginationBttns a:hover {
  color: white;
  background-color: theme("colors.dark-accent");
}

.paginationActive a {
  color: white;
  background-color: #002E5A;
}

.dark .paginationActive a {
  color: white;
  background-color: theme("colors.dark-accent");
}

.paginationDisabled a {
  display:none;
}

.lenteBarradiRicerca{
  margin-top: 2.5px;
}

.panoramicaLink{
  background-color: #93a7c0;
}