.ruota-sdg {
  position: absolute;
  -webkit-animation: ruota-sdg 0.5s forwards ease-out;
          animation: ruota-sdg 0.5s forwards ease-out;
}
.ruota-sdg.on .arc {
  opacity: 0.8;
  transform: rotate(var(--rotation)) !important;
}
.ruota-sdg .arc {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
  transition-property: transform;
  transition-duration: 0.3s;
  transform: rotate(var(--rotation));
  -webkit-animation: arco-ruota-sdg 0.8s forwards ease-out;
          animation: arco-ruota-sdg 0.8s forwards ease-out;
}
.ruota-sdg .arc:hover {
  cursor: pointer;
  filter: brightness(1.2);
}
.ruota-sdg .arc img {
  position: absolute;
  top: 40%;
  left: 5.5%;
  transform: rotate(calc(var(--rotation) * -1));
  transition: color 0.3s;
  width: 60px;
}
.ruota-sdg[data-chosen="1"] .arc:nth-child(1) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(1) {
  --rotation: 0deg;
  transition-delay: 0.015s;
}
.ruota-sdg[data-chosen="2"] .arc:nth-child(2) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(2) {
  --rotation: 21.2deg;
  transition-delay: 0s;
}
.ruota-sdg[data-chosen="3"] .arc:nth-child(3) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(3) {
  --rotation: 42.4deg;
  transition-delay: 0.015s;
}
.ruota-sdg[data-chosen="4"] .arc:nth-child(4) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(4) {
  --rotation: 63.6deg;
  transition-delay: 0s;
}
.ruota-sdg[data-chosen="5"] .arc:nth-child(5) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(5) {
  --rotation: 84.8deg;
  transition-delay: 0.015s;
}
.ruota-sdg[data-chosen="6"] .arc:nth-child(6) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(6) {
  --rotation: 106deg;
  transition-delay: 0s;
}
.ruota-sdg[data-chosen="7"] .arc:nth-child(7) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(7) {
  --rotation: 127.2deg;
  transition-delay: 0.015s;
}
.ruota-sdg[data-chosen="8"] .arc:nth-child(8) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(8) {
  --rotation: 148.4deg;
  transition-delay: 0s;
}
.ruota-sdg[data-chosen="9"] .arc:nth-child(9) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(9) {
  --rotation: 169.6deg;
  transition-delay: 0.015s;
}
.ruota-sdg[data-chosen="10"] .arc:nth-child(10) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(10) {
  --rotation: 190.8deg;
  transition-delay: 0s;
}
.ruota-sdg[data-chosen="11"] .arc:nth-child(11) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(11) {
  --rotation: 212deg;
  transition-delay: 0.015s;
}
.ruota-sdg[data-chosen="12"] .arc:nth-child(12) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(12) {
  --rotation: 233.2deg;
  transition-delay: 0s;
}
.ruota-sdg[data-chosen="13"] .arc:nth-child(13) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(13) {
  --rotation: 254.4deg;
  transition-delay: 0.015s;
}
.ruota-sdg[data-chosen="14"] .arc:nth-child(14) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(14) {
  --rotation: 275.6deg;
  transition-delay: 0s;
}
.ruota-sdg[data-chosen="15"] .arc:nth-child(15) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(15) {
  --rotation: 296.8deg;
  transition-delay: 0.015s;
}
.ruota-sdg[data-chosen="16"] .arc:nth-child(16) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(16) {
  --rotation: 318deg;
  transition-delay: 0s;
}
.ruota-sdg[data-chosen="17"] .arc:nth-child(17) {
  opacity: 1;
  transform: rotate(var(--rotation)) !important;
  filter: brightness(150%);
}
.ruota-sdg .arc:nth-child(17) {
  --rotation: 339.2deg;
  transition-delay: 0.015s;
}

@-webkit-keyframes ruota-sdg {
  0% {
    transform: scale(1.2);
    filter: blur(20px);
  }
  100% {
    transform: scale(1);
    filter: blur(0);
  }
}

@keyframes ruota-sdg {
  0% {
    transform: scale(1.2);
    filter: blur(20px);
  }
  100% {
    transform: scale(1);
    filter: blur(0);
  }
}
@-webkit-keyframes arco-ruota-sdg {
  0% {
    -webkit-clip-path: polygon(10% 80%, 39% 10%, 0% 100%);
            clip-path: polygon(10% 80%, 39% 10%, 0% 100%);
    transform-origin: 0% 80%;
  }
  100% {
    -webkit-clip-path: polygon(0% 0%, 39% 0%, 0% 100%);
            clip-path: polygon(0% 0%, 39% 0%, 0% 100%);
    transform-origin: 0% 100%;
  }
}
@keyframes arco-ruota-sdg {
  0% {
    -webkit-clip-path: polygon(10% 80%, 39% 10%, 0% 100%);
            clip-path: polygon(10% 80%, 39% 10%, 0% 100%);
    transform-origin: 0% 80%;
  }
  100% {
    -webkit-clip-path: polygon(0% 0%, 39% 0%, 0% 100%);
            clip-path: polygon(0% 0%, 39% 0%, 0% 100%);
    transform-origin: 0% 100%;
  }
}/*# sourceMappingURL=ruota-sdg.css.map */