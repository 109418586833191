@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-navigazione-modulo {
        @apply block bg-white dark:bg-darkgray-dd hover:opacity-80 text-accent text-center p-4 font-bold flex justify-center items-center gap-2 duration-200 basis-0
    }

    .btn-navigazione-modulo-attivo {
        @apply bg-accent dark:bg-dark-accent text-white dark:text-white
    }
}

* {
    box-sizing: border-box;
    font-size: 0.9rem;

    /* width */
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: theme('colors.lightgray-d');
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: theme('colors.accent');
        border-radius: 2px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        filter: brightness(1.15)
    }

    &::selection {
        background: theme('colors.accent');
        color: white;
    }
}

a {
    color: theme('colors.accent');
}

a,
button {
    transition-duration: 0.2s;

    &:hover {
        filter: brightness(1.15);
    }
}


.dark {
    a {
        color: theme('colors.dark-accent');
    }
}

#follow-mouse {
    transform: translate(10px, 10px)
}

button {
    background-color: theme('colors.accent');
}

.dark {
    button {
        background-color: theme('colors.dark-accent');
    }
}

/*
 .form gestisce gli stili di tutti i form
*/

.form {
    margin-bottom: 10px;
    margin-top: 6px;
    width: 100%;

    .multi-select {
        margin-bottom: 20px;
    }

    label {
        margin-bottom: 4px;
        display: block;
    }

    input {
        display: block;
        width: 100%;
        transition-duration: 0.2s;
        accent-color: theme('colors.accent');

        &:disabled {
            filter: brightness(0.8);
            pointer-events: none;
        }
    }

    //Grafica di multiselect
    .rmsc {
        .dropdown-container {
            background-color: rgba(0, 0, 0, 0.1);
            padding: 3px 0;
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 2px;
        }
    }

    //Grafica di react-select
    .css-b62m3t-container {
        background-color: rgba(0, 0, 0, 0.1);
        padding: 3px 0;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 2px;

        .css-1s2u09g-control {
            background-color: rgba(0, 0, 0, 0);
            border: none;

            .css-1hwfws3 {
                color: theme('colors.text');
            }
        }

        .css-1pahdxg-control {
            background-color: rgba(0, 0, 0, 0);
            border: none;
            box-shadow: none;

            .css-1hwfws3 {
                color: theme('colors.text');
            }
        }

        .css-qc6sy-singleValue {
            color: #6a6a6a;
        }
    }

    input[type=text],
    input[type=password],
    input[type=email],
    input[type=tel],
    input[type=time],
    input[type=number],
    input[type=search],
    input[type=url],
    input[type=submit],
    select,
    textarea {
        border: 1px solid rgba(0, 0, 0, 0.3);
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        padding: 12px 8px;
        width: 100%;
        display: block;

        &:focus {
            outline-color: theme('colors.accent');
        }
    }

    input[type=submit] {
        display: block;
        margin-top: 0;
        border: none;
        color: theme('colors.lightgray');
        background-color: theme('colors.accent');
        cursor: pointer;
        text-transform: capitalize;

        &:hover {
            filter: brightness(1.15);
        }
    }

    input[type=checkbox] {
        width: auto;
        width: 15px;
        height: 15px;
    }
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    background-color: theme('colors.lightgray-d');
    padding: 10px;
    border-radius: 2px;

    label {
        margin: 0;
    }
}

.dark {
    .checkbox {
        background-color: theme('colors.darkgray-d');
    }

    input {
        accent-color: theme('colors.dark-accent');
    }

    input[type=submit] {
        background-color: theme('colors.dark-accent');
    }

    option {
        background-color: theme('colors.darkgray-d');
        color: white;
    }
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
}

.ipe-shad {
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.15);
}

.spinner {
    animation: spinner 2s infinite linear;
}

@keyframes spinner {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.toggle-theme {
    width: 40px;
    height: 20px;
    padding: 3px;
    border-radius: 10px;
    cursor: pointer;

    div {
        position: relative;
        width: 14px;
        height: 14px;
        left: 0px;
        border-radius: 50%;
        transition-duration: 0.2s;
    }

    .toggle-dark {
        left: 20px;
    }
}

.ipe-logo {
    path {
        fill: theme('colors.accent');
    }
}

.dark {
    .ipe-logo {
        path {
            fill: theme('colors.dark-accent');
        }
    }
}

.modulotile-line {
    background: linear-gradient(0deg, theme('colors.accent') 50%, rgba(0, 0, 0, 0) 90%);
}

.text-loader {
    animation: textLoader 0.5s linear infinite;
    background-image: src('./loadshader.jpg');
    mix-blend-mode: multiply;
    opacity: 0.2;
}

@keyframes textLoader {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: 300px;
    }
}

.tabella-generica {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 2px;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 900px) {
        max-width: calc(100vw - 20px);
    }

    thead {
        font-weight: 600;
        text-align: left;
        min-width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    tbody {
        min-width: 100%;

        tr {
            &:nth-child(even) {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }

    th,
    td {
        padding: 15px 20px;
        text-align: left;
        font-weight: normal;
        min-width: 80px;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
    }

    thead th {
        font-weight: bold;
    }

    tr th:first-child {
        font-weight: bold;
    }
}

.tabella-generica-minificata {
    width: 100%;
    text-align: left;

    thead {
        background-color: rgba(0, 0, 0, 0.1);
    }

    td,
    th {
        padding: 5px;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    tbody {
        tr {
            width: 100%;

            &:nth-child(even) {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.testo-attivita {
    a {
        font-weight: bold;
    }

    .azione {
        padding: 3px 6px;
        border-radius: 4px;
        color: white;
        font-weight: bold;
    }
}

.termini-condizioni {
    p {
        margin: 10px 0;
        text-align: justify;
    }
}

.aside-animato {
    animation: aside 0.1s ease-out forwards;
}

.dialogue-animato {
    animation: dialogue 0.2s ease-out forwards;
}

.sfondo-dialogue-animato {
    animation: sfondo-dialogue 0.2s ease-out forwards;
}

@keyframes aside {
    from {
        opacity: 0;
        width: 0px;
        min-width: 0px;
    }

    to {
        opacity: 1;
        width: 400px;
        min-width: 400px;
    }
}

@keyframes dialogue {
    from {
        opacity: 0;
        transform: scale(0.7);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes sfondo-dialogue {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#toast {
    animation: toast 2s ease-in-out;
}

@keyframes toast {
    0% {
        opacity: 0;
        transform: translateY(-20px)
    }

    20% {
        opacity: 1;
        transform: translateY(0)
    }

    80% {
        opacity: 1;
        transform: translateY(0)
    }

    100% {
        opacity: 0;
        transform: translateY(-20px)
    }
}